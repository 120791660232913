export const academyLoginTheme = {
  formContainer: {
    'margin': '0'
  },
  formSection: {
    'background': 'none'
  },
  sectionHeader: {
    'fontSize': '35px',
    'color': '#fff',
    'fontWeight': 'bold',
  },
  toast: {
    'background': '#c52e21',
    'color': '#fff',
  },
  inputLabel: {
    'color': '#fff'
  }
};
