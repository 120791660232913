export const dict = {
  pt_BR: {
    "Sign in with Google": "Entrar com Google",
    "Sign in with Facebook": "Entrar com Facebook",
    Admin: "Administrativo",
    "Sign In": "Entrar",
    "Sign Up": "Cadastre-se agora",
    "Sign Out": "Sair",
    "Forgot Password": "Esqueceu a senha?",
    Username: "Usuário",
    Password: "Senha",
    "Confirm Password": "Confirmar senha",
    "Change Password": "Mudar senha",
    Change: "Alterar",
    "New Password": "Nova senha",
    Email: "Email",
    "Phone Number": "Telefone",
    "Phone Number (optional)": "Telefone (opcional)",
    "Confirm a Code": "Confirmar um código",
    "Confirm Sign In": "Confirmar ínicio de sessão",
    "Confirm Sign Up": "Confirmar cadastro",
    "Back to Sign In": "Voltar para o login",
    "Send Code": "Enviar código",
    Confirm: "Confirmar",
    "Resend a Code": "Reenviar um código",
    Submit: "Enviar",
    Skip: "Pular",
    Verify: "Verificar",
    "Verify Contact": "Verificar contato",
    Code: "Código",
    "Account recovery requires verified contact information":
      "Para recuperar sua conta é necessário verificar suas informações de contato",
    "User does not exist": "Email não existe",
    "Temporary password has expired and must be reset by an administrator.":
      "A senha temporária expirou e deve ser redefinida, por favor entre em contato com um administrador.",
    "User already exists": "Email já cadastrado",
    "Incorrect username or password": "Usuário/Email ou Senha incorretos",
    "Invalid password format":
      "Formato da senha inválido. Use letras e números",
    "Invalid phone number format": `Número de telefone inválido. Ex.: +12345678900`,
    "The following fields need to be filled out: ":
      "Verifique os seguintes campos: ",
    "Sign in to your account": "Fazer login",
    "Enter your username": "Seu nome",
    "Enter your password": "Digite aqui sua senha",
    "Enter your email": "Digite aqui seu email",
    "Enter your code": "Seu código",
    "Forget your password? ": " ",
    "Password attempts exceeded": "Número de tentativas excedido",
    "Reset password": "Esqueceu a senha?",
    "No account? ": " ",
    "Create Account": "",
    "Create account": "Cadastre-se agora",
    "Create a new account": "Crie sua conta",
    "Have an account? ": "Já possui uma conta? ",
    "Sign in": "Entrar",
    "Reset your password": "Criar uma nova senha",
    "Username cannot be empty": "Usuário não pode ser vazio",
    "null invocation failed due to configuration.":
      "É necessário preencher a senha",
    "An account with the given email already exists.":
      "Já existe um usuário com esse email",
    "Confirmation Code *": "Já existe um usuário com esse email",
    "Full Name": "Nome Completo",
    Continue: "Continuar",
    "The following fields need to be filled out: Full Name, Email, Phone Number, Password":
      "Os seguintes campos precisam ser preenchidos: Nome Completo, Email, Telefone e Senha.",
    "The following fields need to be filled out: Full Name, Email, Password":
      "Os seguintes campos precisam ser preenchidos: Nome Completo, Email e Senha.",
    "The following fields need to be filled out: Email, Password":
      "Os seguintes campos precisam ser preenchidos: Email, Senha.",
    "The following fields need to be filled out: Full Name, Password":
      "Os seguintes campos precisam ser preenchidos: Nome Completo, Senha.",
    "The following fields need to be filled out: Full Name, Email":
      "Os seguintes campos precisam ser preenchidos: Nome Completo, Email.",
    "The following fields need to be filled out: Full Name, Phone Number":
      "Os seguintes campos precisam ser preenchidos: Nome Completo e Telefone.",
    "The following fields need to be filled out: Email, Phone Number":
      "Os seguintes campos precisam ser preenchidos: Email e Telefone.",
    "The following fields need to be filled out: Phone Number, Password":
      "Os seguintes campos precisam ser preenchidos: Telefone e Senha.",
    "The following fields need to be filled out: Email, Phone Number, Password":
      "Os seguintes campos precisam ser preenchidos: Email, Telefone e Senha.",
    "The following fields need to be filled out: Full Name, Email, Phone Number":
      "Os seguintes campos precisam ser preenchidos: Nome Completo, Email e Telefone.",
    "The following fields need to be filled out: Full Name, Phone Number, Password":
      "Os seguintes campos precisam ser preenchidos: Nome Completo, Telefone e Senha.",
    "The following fields need to be filled out: Password":
      "A Senha é obrigatoria.",
    "The following fields need to be filled out: Email":
      "O Email é obrigatório.",
    "The following fields need to be filled out: Full Name":
      "O Nome Completo é obrigatório.",
    "The following fields need to be filled out: Phone Number":
      "O Telefone é obrigatório.",
    "Password did not conform with policy: Password not long enough":
      "A Senha não é longa o suficiente.",
    "Invalid email address format.": "Formato de Email não é válido",
    "Username/client id combination not found.": "Email não encontrado",
    "Fill in your details":
      "Preencha seus dados e tenha acesso ao conteúdo agora mesmo",
    "Lost your code? ": "Perdeu o seu código? ",
    "Resend Code": "Reenviar código",
    "Confirmation Code": "Código de confirmação",
    "Code cannot be empty": "O código não pode estar vazio",
    "Invalid verification code provided, please try again.":
      "Código de verificação inválido, tente novamente.",
    "Attempt limit exceeded, please try after some time.":
      "Limite de tentativa excedido. Tente depois de algum tempo.",
    "Value at 'password' failed to satisfy constraint": "Senha Inválida",
    "Member must have length greater than or equal to 6":
      "Sua senha é muito curta, mínimo de 8 caracteres",
    "Username should be an email": "Seu email não é válido",
    "User cannot be confirmed. Current status is CONFIRMED":
      "Este usuário já está confirmado",
    "Temporary password has expired and must be reset by a administrator.":
      "A senha temporária expirou e deve ser redefinida por um administrador.",
  },
};
