import React from 'react';
import config from '../../aws-exports';
import {
  Authenticator,
  ConfirmSignIn,
  ConfirmSignUp,
  ForgotPassword,
  RequireNewPassword,
  SignIn,
  SignUp,
  VerifyContact
} from 'aws-amplify-react';
import { academyLoginTheme, adminLoginTheme } from '../../theme';
import { settings } from '../../configs/settings';
import { usernameAttributes } from '../../configs/signUpConfig';
import { signUpConfig } from '../../configs/signUpConfig'
import { SignUp as CustomSignUp } from '../custom-pages/SignUp';
import { CustomSignIn } from '../custom-pages/SignIn';
import { RequireNewPassword as CustomRequireNewPassword } from '../custom-pages/RequireNewPassword'
import { getRedirectUrl, hasAdmin, createCookie, clearCookie, redirectTo } from '../../util/util';
import logo from "../../assets/images/new_logo.png";
import { BoxLogin } from './styles';
import { Auth } from 'aws-amplify';
import { FadeIn, FadeInSlow } from '../../style'

export default class Authentication extends React.Component {
  constructor() {
    super();
    this.state = {
      authState: '',
      page: window.location.href.indexOf('signUp') > -1 ? 'signUp' : ''
    };
  }

  redirectToState = () => {
    switch (this.props.page) {
      case 'renew':
        return 'forgotPassword';
      case 'signUp':
        return 'signUp';
      default:
        return 'signIn'
    }
  };

  async isAuth() {
    try {
      const response = await Auth.currentSession();
      createCookie(response);

      this.setState({
        authState: 'signedIn'
      })
    }
    catch (e) {
      clearCookie();
      this.setState({
        authState: 'signOut'
      })
    }
  }

  onStateChange = async (authState) => {
    this.setState({ authState });
    await this.isAuth();

    if (authState === 'signedIn') {
      getRedirectUrl()
    }
  };

  render() {
    const { authState, page } = this.props;
    return (

      <div className={`bg_component${hasAdmin() ? ' has-admin' : ''}`}>

        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-6 offset-sm-3 container-login">

              <div className="painelBox">
                {authState !== 'signedIn' ? (
                  <Authenticator
                    authState={this.redirectToState()}
                    amplifyConfig={config}
                    onStateChange={this.onStateChange}
                    usernameAttributes={usernameAttributes}
                    theme={hasAdmin() ? adminLoginTheme : academyLoginTheme}
                    hide={[
                      SignIn,
                      ConfirmSignIn,
                      RequireNewPassword,
                      SignUp,
                      ConfirmSignUp,
                      VerifyContact,
                      ForgotPassword,
                    ]}
                    hideDefault={true}
                  >
                    {/* <SignIn /> */}
                    <ConfirmSignIn />
                    <CustomRequireNewPassword />
                    <CustomSignIn/>
                    <CustomSignUp override="SignUp" signUpConfig={signUpConfig} />
                    <ConfirmSignUp />
                    <VerifyContact />
                    <ForgotPassword />
                  </Authenticator>
                ) : (
                    <div className="force-align-center">
                      <div className="spinner" />
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>

    )
  }
}
