import { slideInUp ,fadeIn} from 'react-animations';
import styled, { keyframes } from 'styled-components';

const fade = keyframes`${fadeIn}`;
 
export const FadeInSlow = styled.div` 
  animation: 12s ${fade};
`;

 
export const FadeIn = styled.div`
  animation: 2s ${fade};
`;