import React from "react";
import {RequireNewPassword as RequireNewPasswordBase} from "aws-amplify-react";
import {I18n} from 'aws-amplify'

export class RequireNewPassword extends RequireNewPasswordBase {

  constructor(props){
    super(props)
    this.state = {
      disabled: false
    }
  }

  showComponent(theme) {
    const validPassword = (password) => {
      let valid = false;
      let message = ''

      if(password.length < 8){
        message = 'Senha com no mínimo 8 caracteres'
      }
      else if (password.length > 24){
        message = 'Senha com no máximo 24 caracteres'
      }
      else if (!password.match(/[a-z]/)){
        message = 'Pelo menos um caractere minúsculo'
      }
      else if (!password.match(/[A-Z]/)){
        message = 'Pelo menos um caractere maiúsculo'
      }
      else if (!password.match(/[0-9]/)){
        message = 'Pelo menos um número'
      }
      else if (!password.match(/[@$!%*#?&.<>]/)){
        message = 'Pelo menos um caractere especial ex: @,$,!,%...'
      }
      else{
        valid = true
      }
      
      return {valid, message}
    }

    const SubmitForm = (e) => {
      this.setState({disabled: true})

      let pwd = document.getElementsByName("password")[0].value;
      let error = document.getElementById("error")

      let el = document.getElementById('flashDiv');
      if (el) {
        el.remove();
      }

      const password = validPassword(pwd)

      if(password.valid){
        super.change()
        this.setState({disabled: false})
      }else{
        error.innerHTML = password.message
        this.setState({disabled: false})
      }
    };

    return (
        <form>
          <div className="form-require-new-password">
            <input
              placeholder={`${I18n.get('New Password')}`}
              type="password"
              name="password"
              key="password"
              className="input"
              onChange={this.handleInputChange}
            />
            <div id="error" className="error"/>
            <div className="">
              <div className="">
                <button className="text-center btn" disabled={this.state.disabled} type="button" onClick={() => SubmitForm()}>
                  {I18n.get('Change')}
                </button>
                <div ><a className="back-to-sign-in" onClick={() => super.changeState("signIn")}>
                  {I18n.get('Back to Sign In')}
                </a></div>
              </div>
            </div>

          </div>

        </form>
    )
  }
}
