import Cookie from 'js-cookie';
import { Auth } from 'aws-amplify';
import awsmobile from '../aws-exports';

export const createCookie = (response) => {
  if (!response || !response.hasOwnProperty('idToken')){
    redirectToLogin();
  }
  if (window.location.origin.indexOf('localhost')) {
    Cookie.set(process.env.REACT_APP_TOKEN, response.idToken.jwtToken);
  }
  Cookie.set(process.env.REACT_APP_TOKEN, response.idToken.jwtToken, {
    domain: process.env.REACT_APP_DOMAIN
  });
};

export const clearCookie = () => {
  localStorage.clear();
  if (window.location.origin.indexOf('localhost')) {
    Cookie.remove(process.env.REACT_APP_TOKEN);
  }
  Cookie.remove(process.env.REACT_APP_TOKEN, {
    domain: process.env.REACT_APP_DOMAIN
  });
};

export const hasAdmin = () => {
  const params = new URLSearchParams(window.location.search);
  return params.get('adm') === 'true';
};

export const redirectTo = (redirect = '') => {
  if (redirect) {
    window.location.href = redirect;
  } else if (hasAdmin()) {
    window.location.href = `${process.env.REACT_APP_ADMIN_URL}`
  } else {
    window.location.href = `${process.env.REACT_APP_ACADEMY_URL}`;
  }
};

export const redirectToLogin = (redirect = '') => {
  if (redirect) {
    if (hasAdmin()) {
      window.location.href = `/?login=${btoa(redirect)}&adm=true`;
    } else {
      window.location.href = `/?login=${btoa(redirect)}`;
    }
  } else {
    if (hasAdmin()) {
      window.location.href = `/?login=${btoa(`${process.env.REACT_APP_ADMIN_URL}`)}&adm=true`;
    } else {
      window.location.href = `/?login=${btoa(`${process.env.REACT_APP_ACADEMY_URL}`)}`;
    }
  }
};

export const redirectToSamlLogin = () => {
  const signinUri = `${process.env.REACT_APP_SIGNIN_URL}/?loggedIn`;
  const loginUri = `https://${process.env.REACT_APP_SAML_DOMAIN}/login?client_id=${awsmobile.aws_user_pools_web_client_id}&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+profile&redirect_uri=${signinUri}`;
  console.log("Redirecting to: " + loginUri);
  window.location = loginUri;
};

export const createSamlCookie = () => {
  let authToken = getParameterByName("id_token");
  let cognitoError = getParameterByName("error");

  if (cognitoError) {
    document.write(getParameterByName("error_description"));
  } else {
    if (window.location.origin.indexOf('localhost')) {
      Cookie.set(process.env.REACT_APP_TOKEN, authToken);
    }
    Cookie.set(process.env.REACT_APP_TOKEN, authToken, {
      domain: process.env.REACT_APP_DOMAIN
    });
    return authToken;
  }
};

export const getParameterByName = (name) => {
  const url = window.location.href;
  console.log("url: " + window.location.href);

  name = name.replace(/[\[\]]/g, "\\$&");
  let regex = new RegExp("[?#&]" + name + "(=([^&#]*)|&|#|$)");
  let results = regex.exec(url);
  console.log("results: " + results);

  if (!results) return null;
  if (!results[2]) return '';

  return decodeURIComponent(results[2].replace(/\+/g, " "));
};

export const getRedirectUrl = () => {
  const params = new URLSearchParams(window.location.search);
  const routes = ['signUp', 'renew', 'logout'];

  routes.forEach(route => {
    if (params.get(route) !== null){
      redirectTo(atob(params.get(route)));
    }
  });
  redirectTo('');
};
