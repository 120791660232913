import React from "react";
import { SignIn } from "aws-amplify-react";
import { Auth, I18n } from "aws-amplify";
import logo from "../../assets/images/new_logo.png";

export class CustomSignIn extends SignIn {
  constructor(props) {
    super(props);
    this._validAuthStates = ["signIn", "signedOut", "signedUp"];
    this.state = {
      username: null,
    };
  }

  restrictSpace(event) {
    if (event.charCode === 32 || event.keyCode === 32) {
      event.preventDefault();
      return false;
    }
  }

  handleUsername() {
    this.setState({
      username: localStorage.getItem("username"),
    });
  }

  componentDidMount = async () => {
    await this.handleUsername();
  };

  componentWillMount() {
    this.handleUsername();
  }

  showComponent(theme) {
    return (
      <div className="cardMiddle">
        <div className="row title-logo-mobile">
          <div className="col col-4 title-logo">
            <img src={logo} className="img-fluid logo" />
          </div>
          <h1 className="text-title col col-8">
            <span className="text-title-decoration-red">
              {I18n.get("Sign in to your account").substring(2, 0)}
            </span>
            <span className="text-title-decoration-white">
              {I18n.get("Sign in to your account").substring(2)}
            </span>
          </h1>
        </div>

        <form className="form-signIn">
          <input
            className="input"
            key="email"
            name="email"
            onChange={this.handleInputChange}
            defaultValue={this.state.username ? atob(this.state.username) : ""}
            onKeyPress={(event) => this.restrictSpace(event)}
            type="email"
            placeholder={I18n.get("Enter your email")}
          />

          <input
            className="input"
            id="password"
            key="password"
            name="password"
            onChange={this.handleInputChange}
            type="password"
            placeholder={I18n.get("Enter your password")}
          />
        </form>
        <div className="row login-button">
          <div className="col-6">
            <div>
              <a
                className="sign-up"
                onClick={() => super.changeState("signUp")}
              >
                {I18n.get("Sign Up")}
              </a>
            </div>

            <div>
              <a
                className="forgot-password"
                onClick={() => super.changeState("forgotPassword")}
              >
                Esqueceu a <b>senha ?</b>
              </a>
            </div>
          </div>

          <div className="col-6">
            <button
              className="btn"
              type="button"
              onClick={(event) => this.signIn(event)}
            >
              {I18n.get("Sign in")}
            </button>
          </div>
        </div>
      </div>
    );
  }
}
