import React from "react";
import { SignUp as SingUpBase } from "aws-amplify-react";
import { I18n } from 'aws-amplify'
import PhoneField from "aws-amplify-react/dist/aws-amplify-react";
import logo from "../../assets/images/new_logo.png";

export class SignUp extends SingUpBase {

  showComponent(theme) {

    this.signUpFields = this.props.signUpConfig.signUpFields;


    const SubmitForm = (e) => {

      let pwd = document.getElementsByName("password")[0].value;
      let pwd_confirm = document.getElementsByName("confirm_password")[0].value;


      let el = document.getElementById('flashDiv');
      if (el) {
        el.remove();
      }

      if (pwd === pwd_confirm) {
        super.signUp()
      } else {
        let flashDiv = document.createElement('div');
        flashDiv.id = 'flashDiv';
        document.getElementsByTagName('body')[0].appendChild(flashDiv);
        flashDiv.innerHTML = "Senhas estão diferentes";
      }
    };

    const Field = ({ field, ...props }) => {

      let columns = "";

      if (field.key === "name") {
        columns = "col-sm-12";
      } else {
        columns = "col-sm-6";
      }

      return (
        <div className={`col ${columns}`}>

          {field.key !== 'phone_number' ? (
            <input
              autoFocus={
                this.signUpFields.findIndex((f) => {
                  return f.key === field.key
                }) === 0
              }
              placeholder={`${I18n.get(field.label)}${!field.required ? ' (opcional)' : ''}`}
              type={field.type}
              name={field.key}
              key={field.key}
              onChange={this.handleInputChange}
              className="input"
            />
          ) : (
              <PhoneField
                required={field.required}
                defaultDialCode={this.getDefaultDialCode()}
                className="input"
                label={field.label}
                placeholder={`${I18n.get(field.label)}${!field.required ? ' (opcional)' : ''}`}
                onChangeText={this.onPhoneNumberChanged}
                key="phone_number"
              />
            )}

        </div>
      );
    };

    return (
      <div className="form-signUp">

        <div className="row">
          <div className="col col-4 title-logo">
            <img
              src={logo}
              className="img-fluid logo"
            />
          </div>
          <h1 className="text-title">
            <span className="text-title-decoration-red">{I18n.get("Sign Up").substring(2, 0)}</span>
            <span className="text-title-decoration-white">{I18n.get("Sign Up").substring(2)}</span>
          </h1>
        </div>

        <form>
          <div className="row text-center">
            {this.signUpFields.map((field, index) => (
              <Field key={index} field={field} />
            ))}

            <div className="col">
              <input
                placeholder={`${I18n.get('Confirm Password')}`}
                type="password"
                name="confirm_password"
                key="confirm_password"
                className="input"
              />
            </div>
            <div className="col-12 d-flex justify-content-center">
              <div className="">
                <button className="text-center btn" type="button" onClick={() => SubmitForm()}>
                  {I18n.get('Continue')}
                </button>

                <div className="text-center"><a className="back-to-sign-in" onClick={() => super.changeState("signIn")}>
                  {I18n.get('Back to Sign In')}
                </a></div>
              </div>
            </div>

          </div>

        </form>

      </div>
    )
  }
}
